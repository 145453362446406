import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles({
  root: {
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  }
});

const IndexPage = ({ data, location }) => {
  const classes = useStyles();

  const nodes = data.allMdx.edges
  return <Layout>
    <SEO title="Home" />
    <Grid container spacing={3}>
      {nodes.map(({ node }) => {
        const title = node.frontmatter.title || node.frontmatter.slug
        return (
          <Grid key={node.frontmatter.slug} item xs={12}>
            <Card className={classes.root}>
              <ButtonBase href={'/blog/' + node.frontmatter.slug + '/'} className={classes.cardAction}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {node.frontmatter.date}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {node.excerpt}
                  </Typography>
                </CardContent>
              </ButtonBase>
              <CardActions>
                <Button href={'/blog/' + node.frontmatter.slug + '/'} size="small" color="primary">
                  More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  </Layout>
}

export default IndexPage

export const query = graphql`
  {
    allMdx(
      filter: {fileAbsolutePath: {regex: "/content/posts/"}}
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
            date(formatString: "MMMM DD, YYYY")
            tags
          }
          excerpt
        }
      }
    }
  }
`